import {bilinearInterpolation} from "@dmytropaduchak/simple-bilinear-interpolation";
import {linearInterpolation} from "@dmytropaduchak/simple-linear-interpolation";


const windSpeeds = [
    {time: 0, direction: 20, speed: 18}, //19:00
    {time: 1, direction: 20, speed: 19},   //20:00
    {time: 2, direction: 30, speed: 19},   //21:00
    {time: 3, direction: 40, speed: 18},   //22:00
    {time: 4, direction: 50, speed: 18},   //23:00
    {time: 5, direction: 50, speed: 18},   //00:00
    {time: 6, direction: 50, speed: 18},   //01:00
    {time: 7, direction: 50, speed: 19},   //02:00
    {time: 8, direction: 60, speed: 19},   //03:00
    {time: 9, direction: 60, speed: 19},   //04:00
    {time: 10, direction: 60, speed: 20},  //05:00
    {time: 11, direction: 60, speed: 21},  //06:00
    {time: 12, direction: 60, speed: 21},  //07:00
    {time: 13, direction: 60, speed: 21},  //08:00
    {time: 14, direction: 70, speed: 20},  //09:00
    {time: 15, direction: 60, speed: 20},  //10:00
    {time: 16, direction: 60, speed: 19},  //11:00
    {time: 17, direction: 60, speed: 18},  //12:00
    {time: 18, direction: 60, speed: 18},  //13:00
    {time: 19, direction: 60, speed: 18},  //14:00
    {time: 20, direction: 70, speed: 18},  //15:00
    {time: 21, direction: 60, speed: 18},  //16:00
    {time: 22, direction: 70, speed: 18},  //17:00
    {time: 23, direction: 60, speed: 19},  //18:00
    {time: 24, direction: 70, speed: 19},  //19:00
];

const polarData = {
    Data: [
        [0, 0, 0, 0, 0, 0, 0, 0],
        [25, 0, 0, 0, 0, 0, 0, 0],
        [52, 5.02, 5.95, 6.59, 6.96, 7.16, 7.26, 7.34],
        [60, 5.29, 6.2, 6.79, 7.13, 7.33, 7.45, 7.55],
        [75, 5.44, 6.37, 6.94, 7.27, 7.51, 7.69, 7.89],
        [90, 5.32, 6.28, 6.98, 7.37, 7.59, 7.77, 8.14],
        [110, 5.25, 6.37, 7.09, 7.5, 7.82, 8.11, 8.51],
        [120, 5.07, 6.19, 6.97, 7.43, 7.77, 8.09, 8.69],
        [135, 4.55, 5.66, 6.54, 7.13, 7.52, 7.87, 8.54],
        [150, 3.85, 4.94, 5.87, 6.62, 7.15, 7.51, 8.16],
        [180, 3.80, 4.90, 5.80, 6.60, 7.00, 7.10, 7.50],
    ],
    TWS: [6, 8, 10, 12, 14, 16, 20],
};

export function estimateBoatSpeed(course, time) {

    if (time > 24) {
        return 0;
    }

    let tws = estimateTWS(time);
    let windDirection = estimateDirection(time);

    let twa = Math.abs(windDirection - course)> 180 ? 360 - Math.abs(windDirection - course) : Math.abs(windDirection - course)

    console.log("Got speed "+tws+" and angle "+twa);


    // Check if TWS is within the data
    if (tws > 20) {
        tws = 20;
    }

    if (twa > 180) {
        twa = 360 - twa;
    }

    const points = [];

    for (let i = 0; i < polarData.Data.length; i++) {
        for (let j = 0; j < polarData.TWS.length; j++) {
            const x = polarData.TWS[j];
            const y = polarData.Data[i][0]; // Use j + 1 to skip the first column
            const z = polarData.Data[i][j+1];

            points.push({ x, y, z });
        }
    }

    const calculate = bilinearInterpolation(points);
    return calculate({x: tws, y: twa});

}

export function estimateTWS(time) {

    console.log("interpolating time till point: "+time);
    if (time > 24) {
        return 0;
    }

    const points = [];

    for (let i = 0; i < windSpeeds.length; i++) {
        if (windSpeeds[i].time === time) {
            return windSpeeds[i].speed;
        }
        points.push({ x:windSpeeds[i].time, y:windSpeeds[i].speed});
    }

    const calculate = linearInterpolation(points);
    return calculate({x: time});
}

export function estimateDirection(time) {
    const points = [];

    for (let i = 0; i < windSpeeds.length; i++) {
        points.push({ x:windSpeeds[i].time, y:windSpeeds[i].direction});
    }

    const calculate = linearInterpolation(points);
    return calculate({x: time});
}