export const markers = [
    // start markers
    {
        "Name": "STAV",
        "Latitude": 52.87625,
        "Longitude": 5.35968333333333,
        "Type": "start",
    },
    {
        "Name": "LEMMER",
        "Latitude": 52.8366166666667,
        "Longitude": 5.70336666666667,
        "Type": "start",
    },
    {
        "Name": "MED",
        "Latitude": "52.78",
        "Longitude": "5.123332",
        "Type": "start",
    },
    {
        "Name": "LELY-N",
        "Latitude": "52.5394166666667",
        "Longitude": "5.43756666666667",
        "Type": "start",
    },
    {
        "Name": "ENK-N",
        "Latitude": "52.7045333333333",
        "Longitude": "5.30696666666667",
        "Type": "start",
    },
    {
        "Name": "HIND",
        "Latitude": "52.9555",
        "Longitude": "5.39426666666667",
        "Type": "start",
    },
    {
        "Name": "OEVE",
        "Latitude": "52.925",
        "Longitude": "5.051",
        "Type": "start",
    },
    {
        "Name": "FINISH",
        "Latitude": "52.7871666666667",
        "Longitude": "5.12366666666667",
        "Type": "finish",
    },
    {
        "Name": "LELY-Z",
        "Latitude": "52.51965",
        "Longitude": "5.42571666666667",
        "Type": "start",
    },
    {
        "Name": "HOORN",
        "Latitude": "52.6329666666667",
        "Longitude": "5.06463333333333",
        "Type": "start",
    },
    {
        "Name": "ENK-Z",
        "Latitude": "52.6853333333333",
        "Longitude": "5.2791",
        "Type": "start",
    },
    {
        "Name": "VOLENDAM",
        "Latitude": "52.4913333333333",
        "Longitude": "5.09083333333333",
        "Type": "start",
    },
    {
        "Name": "HOEK-IJ",
        "Latitude": "52.4197333333333",
        "Longitude": "5.1446",
        "Type": "start",
    },
    // route markers
    {
        "Name": "SPORT-C",
        "Latitude": 52.8,
        "Longitude": 5.15
    },
    {
        "Name": "SPORT-I",
        "Latitude": "52.5333333333333",
        "Longitude": "5.356",
    },
    {
        "Name": "EA1",
        "Latitude": "52.579616",
        "Longitude": "5.223882",
    },
    {
        "Name": "EA2",
        "Latitude": "52.510916",
        "Longitude": "5.216649",
    },
    {
        "Name": "SPORT-J",
        "Latitude": "52.4666666666667",
        "Longitude": "5.23333333333333",
    },
    {
        "Name": "SPORT-M",
        "Latitude": "52.605",
        "Longitude": "5.31916666666667",
    },
    {
        "Name": "SPORT-G",
        "Latitude": "52.605",
        "Longitude": "5.16333333333333",
    },
    {
        "Name": "SPORT-H",
        "Latitude": "52.535",
        "Longitude": "5.16333333333333",
    },
    {
        "Name": "MN1-GZ2",
        "Latitude": "52.497016",
        "Longitude": "5.10216166666667",
    },
    {
        "Name": "KG10",
        "Latitude": "52.6776166666667",
        "Longitude": "5.26731666666667",
    },
    {
        "Name": "KG18",
        "Latitude": "52.6482",
        "Longitude": "5.23213333333333",
    },
    {
        "Name": "WV19",
        "Latitude": 52.86725,
        "Longitude": 5.148867
    },
    {
        "Name": "WV12",
        "Latitude": 52.895433,
        "Longitude": 5.107633
    },
    {
        "Name": "KR-A",
        "Latitude": 52.825717,
        "Longitude": 5.225633
    },
    {
        "Name": "SPORT-A",
        "Latitude": 52.966667,
        "Longitude": 5.166667
    },
    {
        "Name": "SPORT-B",
        "Latitude": 52.95,
        "Longitude": 5.241667
    },
    {
        "Name": "VF7",
        "Latitude": 53.03665,
        "Longitude": 5.34895
    },
    {
        "Name": "H2-W1",
        "Latitude": 52.958383,
        "Longitude": 5.391217
    },
    {
        "Name": "VF-A",
        "Latitude": 52.94262,
        "Longitude": 5.316522
    },
    {
        "Name": "VF-B",
        "Latitude": 52.9051,
        "Longitude": 5.313833
    },
    {
        "Name": "LC1",
        "Latitude": 52.8557,
        "Longitude": 5.30605
    },
    {
        "Name": "LC11",
        "Latitude": 52.810317,
        "Longitude": 5.303263
    },
    {
        "Name": "SPORT-D",
        "Latitude": 52.758333,
        "Longitude": 5.3
    },
    {
        "Name": "KG",
        "Latitude": 52.7189,
        "Longitude": 5.333437
    },
    {
        "Name": "SB8",
        "Latitude": 52.820395,
        "Longitude": 5.35465
    },
    {
        "Name": "SB18",
        "Latitude": 52.820593,
        "Longitude": 5.427247
    },
    {
        "Name": "SB28",
        "Latitude": 52.82019,
        "Longitude": 5.5023
    },
    {
        "Name": "SB40",
        "Latitude": 52.81958,
        "Longitude": 5.592155
    },
    {
        "Name": "EL-A",
        "Latitude": 52.751813,
        "Longitude": 5.444872
    },
    {
        "Name": "EL-B",
        "Latitude": 52.761317,
        "Longitude": 5.519171
    },
    {
        "Name": "EZ-C",
        "Latitude": 52.70963,
        "Longitude": 5.436628
    },
    {
        "Name": "EZ-D",
        "Latitude": 52.67918,
        "Longitude": 5.498487
    },
    {
        "Name": "SPORT-E",
        "Latitude": 52.616667,
        "Longitude": 5.5
    },
    {
        "Name": "SPORT-F",
        "Latitude": 52.6,
        "Longitude": 5.466667
    }
];

export const routes = [
    {
        Start: 'EL-A',
        End: 'EL-B',
        distance: 2.7594214240187025,
        originalDistance: 2.7538,
        max: 2,
        course: 78.03604505299177,
        reverseCourse: 258.0951923616482
    },
    {
        Start: 'EL-A',
        End: 'LC11',
        distance: 6.2278222362569515,
        originalDistance: 6.2435,
        max: 2,
        course: 304.39051864174826,
        reverseCourse: 124.27775110148258
    },
    {
        Start: 'EL-A',
        End: 'SB28',
        distance: 4.604649558853984,
        originalDistance: 4.6103,
        max: 2,
        course: 26.905425493364987,
        reverseCourse: 206.95116013866263
    },
    {
        Start: 'EL-A',
        End: 'SB8',
        distance: 5.261986688192841,
        originalDistance: 5.3036,
        max: 2,
        course: 321.52927561215444,
        reverseCourse: 141.45742430331632
    },
    {
        Start: 'EL-A',
        End: 'SPORT-D',
        distance: 5.278877564493942,
        originalDistance: 5.297,
        max: 2,
        course: 274.3104260075669,
        reverseCourse: 94.19509981908044
    },
    {
        Start: 'EL-A',
        End: 'EZ-C',
        distance: 2.5503614700087516,
        originalDistance: 2.5526,
        max: 2,
        course: 186.75269683663396,
        reverseCourse: 6.74613627506443
    },
    {
        Start: 'EL-B',
        End: 'SB28',
        distance: 3.587445290114536,
        originalDistance: 3.5886,
        max: 2,
        course: 350.1753204439503,
        reverseCourse: 170.16188383219514
    },
    {
        Start: 'EL-B',
        End: 'SB8',
        distance: 6.947200540123193,
        originalDistance: 6.9749,
        max: 2,
        course: 300.7676526307383,
        reverseCourse: 120.6366225580141
    },
    {
        Start: 'EZ-C',
        End: 'KG',
        distance: 3.7942984984406842,
        originalDistance: 3.8063,
        max: 2,
        course: 278.47604122640223,
        reverseCourse: 98.39393994776447
    },
    {
        Start: 'EZ-C',
        End: 'EL-B',
        distance: 4.316858284395895,
        originalDistance: 4.3161,
        max: 2,
        course: 44.00492635668979,
        reverseCourse: 224.0706180913963
    },
    {
        Start: 'EZ-D',
        End: 'EZ-C',
        distance: 2.899867295997469,
        originalDistance: 2.9066,
        max: 2,
        course: 309.1081954403382,
        reverseCourse: 129.05899190356843
    },
    {
        Start: 'EZ-D',
        End: 'KG',
        distance: 6.4614771436792475,
        originalDistance: 6.4806,
        max: 2,
        course: 291.7245619191955,
        reverseCourse: 111.5932706557939
    },
    {
        Start: 'EZ-D',
        End: 'SB28',
        distance: 8.46743879097941,
        originalDistance: 8.4742,
        max: 2,
        course: 0.9361963347521964,
        reverseCourse: 180.93923147999737
    },
    {
        Start: 'KG',
        End: 'LC11',
        distance: 5.597117648247493,
        originalDistance: 5.6017,
        max: 2,
        course: 348.7173759376611,
        reverseCourse: 168.69335270942713
    },
    {
        Start: 'KG',
        End: 'LC1',
        distance: 8.273516526157302,
        originalDistance: 8.2801,
        max: 2,
        course: 353.1074479398841,
        reverseCourse: 173.08563703008917
    },
    {
        Start: 'KG',
        End: 'EL-A',
        distance: 4.50741551241213,
        originalDistance: 4.5206,
        max: 2,
        course: 63.95297267159492,
        reverseCourse: 244.04165792682983
    },
    {
        Start: 'KG',
        End: 'SPORT-D',
        distance: 2.6613580246883326,
        originalDistance: 2.6644,
        max: 2,
        course: 332.8378000326457,
        reverseCourse: 152.8111881334786
    },
    {
        Start: 'KG',
        End: 'SB28',
        distance: 8.637798656730656,
        originalDistance: 8.6549,
        max: 2,
        course: 45.179462561478545,
        reverseCourse: 225.31391279490552
    },
    {
        Start: 'KG',
        End: 'SB8',
        distance: 6.1423336585424435,
        originalDistance: 6.1862,
        max: 2,
        course: 7.198507123987383,
        reverseCourse: 187.21539712321496
    },
    {
        Start: 'LC1',
        End: 'SPORT-A',
        distance: 8.358128055274191,
        originalDistance: 8.3723,
        max: 2,
        course: 322.912389222603,
        reverseCourse: 142.80120310101108
    },
    {
        Start: 'LC1',
        End: 'SPORT-B',
        distance: 6.123111313823632,
        originalDistance: 6.1302,
        max: 2,
        course: 337.64326477401505,
        reverseCourse: 157.5919119768423
    },
    {
        Start: 'LC1',
        End: 'VF-B',
        distance: 2.979374668700589,
        originalDistance: 2.9818,
        max: 2,
        course: 5.428182212542197,
        reverseCourse: 185.43438820246976
    },
    {
        Start: 'LC11',
        End: 'WV19',
        distance: 6.560532082937552,
        originalDistance: 6.5786,
        max: 2,
        course: 301.46336585923166,
        reverseCourse: 121.34032162480503
    },
    {
        Start: 'LC11',
        End: 'SB8',
        distance: 1.960427362998776,
        originalDistance: 1.9769,
        max: 2,
        course: 72.00158393689242,
        reverseCourse: 252.04252354624873
    },
    {
        Start: 'SB40',
        End: 'EL-B',
        distance: 4.388518194177327,
        originalDistance: 4.4042,
        max: 2,
        course: 217.17395295128225,
        reverseCourse: 37.11582635820582
    },
    {
        Start: 'SB40',
        End: 'SB28',
        distance: 3.2604869806411982,
        originalDistance: 3.2709,
        max: 2,
        course: 270.67940584797253,
        reverseCourse: 90.60781479674193
    },
    {
        Start: 'SB28',
        End: 'SPORT-D',
        distance: 8.230907630294903,
        originalDistance: 8.2534,
        max: 2,
        course: 243.25882769864492,
        reverseCourse: 63.097712538053315
    },
    {
        Start: 'SB8',
        End: 'WV12',
        distance: 10.024343225438267,
        originalDistance: 10.0338,
        max: 2,
        course: 296.80599537997034,
        reverseCourse: 116.60908793863797
    },
    {
        Start: 'SB8',
        End: 'WV19',
        distance: 7.975122574317037,
        originalDistance: 7.9843,
        max: 2,
        course: 290.7373613170783,
        reverseCourse: 110.57335381152268
    },
    {
        Start: 'SB18',
        End: 'SB28',
        distance: 2.723284205103778,
        originalDistance: 2.7326,
        max: 2,
        course: 90.47917894801185,
        reverseCourse: 270.5389770569029
    },
    {
        Start: 'SPORT-B',
        End: 'SPORT-A',
        distance: 2.891302730641267,
        originalDistance: 2.8999,
        max: 2,
        course: 290.27919128823584,
        reverseCourse: 110.21932646086924
    },
    {
        Start: 'SPORT-C',
        End: 'WV12',
        distance: 5.932212215869613,
        originalDistance: 5.9379,
        max: 2,
        course: 345.0080639576294,
        reverseCourse: 164.97429604155525
    },
    {
        Start: 'SPORT-C',
        End: 'WV19',
        distance: 4.03792985562233,
        originalDistance: 4.0411,
        max: 2,
        course: 359.4173061055462,
        reverseCourse: 179.41640323513877
    },
    {
        Start: 'SPORT-D',
        End: 'SPORT-C',
        distance: 5.994631876623206,
        originalDistance: 6.0116,
        max: 2,
        course: 294.725479408046,
        reverseCourse: 114.60603287141328
    },
    {
        Start: 'SPORT-D',
        End: 'LC11',
        distance: 3.1233914853042712,
        originalDistance: 3.1263,
        max: 2,
        course: 2.172830728780184,
        reverseCourse: 182.17542926635198
    },
    {
        Start: 'SPORT-D',
        End: 'SPORT-A',
        distance: 13.409709714171337,
        originalDistance: 13.4249,
        max: 2,
        course: 338.92734647075923,
        reverseCourse: 158.8210546820983
    },
    {
        Start: 'SPORT-D',
        End: 'EZ-C',
        distance: 5.7639822843103605,
        originalDistance: 5.779,
        max: 2,
        course: 120.43071090652785,
        reverseCourse: 300.539443971663
    },
    {
        Start: 'SPORT-E',
        End: 'EL-B',
        distance: 8.712831078916379,
        originalDistance: 8.7176,
        max: 2,
        course: 4.585321166192784,
        reverseCourse: 184.6005689682098
    },
    {
        Start: 'SPORT-E',
        End: 'EZ-D',
        distance: 3.7537137073574356,
        originalDistance: 3.7558,
        max: 2,
        course: 359.1593185532752,
        reverseCourse: 179.15811583555706
    },
    {
        Start: 'SPORT-F',
        End: 'SPORT-E',
        distance: 1.5742965232023431,
        originalDistance: 1.5778,
        max: 2,
        course: 50.51889350613436,
        reverseCourse: 230.54537667363084
    },
    {
        Start: 'VF-A',
        End: 'SPORT-B',
        distance: 2.744126662359073,
        originalDistance: 2.7582,
        max: 2,
        course: 279.3221925425662,
        reverseCourse: 99.26245291830571
    },
    {
        Start: 'VF-A',
        End: 'WV12',
        distance: 8.075283043101768,
        originalDistance: 8.1041,
        max: 2,
        course: 249.54467845816706,
        reverseCourse: 69.37803003418503
    },
    {
        Start: 'VF-A',
        End: 'VF7',
        distance: 5.765973666909575,
        originalDistance: 5.7704,
        max: 2,
        course: 11.714901310914172,
        reverseCourse: 191.74079594133013
    },
    {
        Start: 'VF-A',
        End: 'H2-W1',
        distance: 2.8630172189386163,
        originalDistance: 2.8666,
        max: 2,
        course: 70.66702986469221,
        reverseCourse: 250.72664509043224
    },
    {
        Start: 'VF-B',
        End: 'SPORT-A',
        distance: 6.482660933359773,
        originalDistance: 6.4986,
        max: 2,
        course: 304.82391051966556,
        reverseCourse: 124.70647766941028
    },
    {
        Start: 'VF-B',
        End: 'SPORT-B',
        distance: 3.7536366523780957,
        originalDistance: 3.7611,
        max: 2,
        course: 315.93385328284,
        reverseCourse: 135.87627390908034
    },
    {
        Start: 'VF-B',
        End: 'WV12',
        distance: 7.4903938776402885,
        originalDistance: 7.5147,
        max: 2,
        course: 265.6380820376044,
        reverseCourse: 85.47361958777287
    },
    {
        Start: 'VF-B',
        End: 'H2-W1',
        distance: 4.251772204198164,
        originalDistance: 4.2597,
        max: 2,
        course: 41.16834500237877,
        reverseCourse: 221.23009109741545
    },
    {
        Start: 'WV12',
        End: 'LC1',
        distance: 7.575517842289641,
        originalDistance: 7.5983,
        max: 2,
        course: 108.27610376447251,
        reverseCourse: 288.4343069877872
    },
    {
        Start: 'WV19',
        End: 'VF-B',
        distance: 6.393938737143455,
        originalDistance: 6.4128,
        max: 2,
        course: 69.11508048251244,
        reverseCourse: 249.24663073869144
    },
    {
        Start: 'WV19',
        End: 'SPORT-B',
        distance: 5.9979828385191265,
        originalDistance: 6.0074,
        max: 2,
        course: 34.034784020619554,
        reverseCourse: 214.1088082601459
    },
    {
        Start: 'WV19',
        End: 'VF-A',
        distance: 7.572179817936596,
        originalDistance: 7.5944,
        max: 2,
        course: 53.23389212952827,
        reverseCourse: 233.36761983685705
    },
    {
        Start: 'WV19',
        End: 'LC1',
        distance: 5.73978252300076,
        originalDistance: 5.7583,
        max: 2,
        course: 96.87663902542192,
        reverseCourse: 277.0019419092188
    },
    {
        Start: 'WV19',
        End: 'H2-W1',
        distance: 10.340791173500028,
        originalDistance: 10.3674,
        max: 2,
        course: 57.95642724679601,
        reverseCourse: 238.1497545737551
    },
    {
        Start: 'VF7',
        End: 'H2-W1',
        distance: 4.941163164718519,
        originalDistance: 4.9464,
        max: 2,
        course: 161.97789705781918,
        reverseCourse: 342.01165189074914
    },
    {
        Start: 'KG',
        End: 'SB18',
        distance: 6.992278998305556,
        originalDistance: 7.001,
        max: 2,
        course: 29.12941936708563,
        reverseCourse: 209.20411191567507
    },
    {
        Start: 'SB18',
        End: 'EL-A',
        distance: 4.178881338633861,
        originalDistance: 4.1828,
        max: 2,
        course: 171.18343623508088,
        reverseCourse: 351.1974725110355
    },
    {
        Start: 'KR-A',
        End: 'SPORT-C',
        distance: 3.149203386960603,
        originalDistance: 3.1576,
        max: 2,
        course: 240.6697062850728,
        reverseCourse: 60.60945207224165
    },
    {
        Start: 'KR-A',
        End: 'WV19',
        distance: 3.737256859683349,
        originalDistance: 3.7454,
        max: 2,
        course: 311.8852523882144,
        reverseCourse: 131.8240683311683
    },
    {
        Start: 'KR-A',
        End: 'SPORT-A',
        distance: 8.728044183601586,
        originalDistance: 8.7364,
        max: 2,
        course: 345.85940551348983,
        reverseCourse: 165.81237750653622
    },
    {
        Start: 'KR-A',
        End: 'SPORT-B',
        distance: 7.484581959142857,
        originalDistance: 7.4907,
        max: 2,
        course: 4.444700171601596,
        reverseCourse: 184.45748659002925
    },
    {
        Start: 'KR-A',
        End: 'VF7',
        distance: 13.427867234292483,
        originalDistance: 13.4424,
        max: 2,
        course: 19.363148195739882,
        reverseCourse: 199.46154450384583
    },
    {
        Start: 'KR-A',
        End: 'VF-A',
        distance: 7.752959438970567,
        originalDistance: 7.7647,
        max: 2,
        course: 25.097374445620005,
        reverseCourse: 205.1698509430225
    },
    {
        Start: 'KR-A',
        End: 'LC1',
        distance: 3.427286878176933,
        originalDistance: 3.4361,
        max: 2,
        course: 58.28268055299759,
        reverseCourse: 238.34676963369395
    },
    {
        Start: 'KR-A',
        End: 'SB8',
        distance: 4.691779402686481,
        originalDistance: 4.7038,
        max: 2,
        course: 93.85377158558697,
        reverseCourse: 273.9565688825487
    },
    {
        Start: 'KR-A',
        End: 'LC11',
        distance: 2.964704862550241,
        originalDistance: 2.9748,
        max: 2,
        course: 108.14145065299039,
        reverseCourse: 288.2033000304859
    },
    {
        Start: 'KR-A',
        End: 'SPORT-D',
        distance: 4.863997699323877,
        originalDistance: 4.8716,
        max: 2,
        course: 146.25218366307848,
        reverseCourse: 326.31141295790724
    },
    {
        Start: 'ENK-N',
        End: 'KG',
        distance: 1.2927081281905544,
        originalDistance: 1.3053,
        max: 1,
        course: 48.13300658624496,
        reverseCourse: 228.15406631454087
    },
    {
        Start: 'HIND',
        End: 'H2-W1',
        distance: 0.20525464353419356,
        originalDistance: 0.2135,
        max: 1,
        course: 327.49426964570836,
        reverseCourse: 147.49183545357937
    },
    {
        Start: 'LELY-N',
        End: 'SPORT-E',
        distance: 5.167324105502686,
        originalDistance: 5.2806,
        max: 1,
        course: 26.131981304350916,
        reverseCourse: 206.1815647338582
    },
    {
        Start: 'MED',
        End: 'SPORT-C',
        distance: 1.5425667633660958,
        originalDistance: 1.5452,
        max: 1,
        course: 38.87062111527217,
        reverseCourse: 218.89186016119805
    },
    {
        Start: 'OEVE',
        End: 'WV12',
        distance: 2.7122498539123456,
        originalDistance: 2.716,
        max: 1,
        course: 130.86051450505278,
        reverseCourse: 310.9056901692052
    },
    {
        Start: 'STAV',
        End: 'LC1',
        distance: 2.3024570391477375,
        originalDistance: 2.2436,
        max: 1,
        course: 237.61804574578187,
        reverseCourse: 57.575287878994345
    },
    {
        Start: 'LEMMER',
        End: 'SB40',
        distance: 4.162071978176727,
        originalDistance: 4.1802,
        max: 1,
        course: 255.81730053494113,
        reverseCourse: 75.72868414041164
    },
    {
        Start: 'WV19',
        End: 'FINISH',
        distance: 4.8943794320516645,
        originalDistance: 4.8943,
        max: 1,
        course: 190.77541873117733,
        reverseCourse: 10.75533867373224
    },
    {
        Start: 'HOORN',
        End: 'SPORT-G',
        distance: 3.9702957517090343,
        originalDistance: '3.96762046674763',
        max: '1',
        course: 114.98007639095522,
        reverseCourse: 295.05850498125403
    },
    {
        Start: 'HOEK-IJ',
        End: 'SPORT-J',
        distance: 4.299567811365266,
        originalDistance: '4.29667065462308',
        max: '1',
        course: 49.0155243411416,
        reverseCourse: 229.08586765405965
    },
    {
        Start: 'VOLENDAM',
        End: 'MN1-GZ2',
        distance: 0.5365601691633715,
        originalDistance: '0.53619862144982',
        max: '1',
        course: 50.50994409099616,
        reverseCourse: 230.5189307609209
    },
    {
        Start: 'LELY-Z',
        End: 'SPORT-I',
        distance: 2.6758691648839115,
        originalDistance: '2.67406609705643',
        max: '1',
        course: 287.90758030543566,
        reverseCourse: 107.85225073483019
    },
    {
        Start: 'ENK-Z',
        End: 'KG10',
        distance: 0.6313615107926398,
        originalDistance: '0.630936083554837',
        max: '1',
        course: 222.79621768742228,
        reverseCourse: 42.78684666739542
    },
    {
        Start: 'EA1',
        End: 'KG10',
        distance: 6.093200193906795,
        originalDistance: '6.08909444263176',
        max: '2',
        course: 15.039856863682644,
        reverseCourse: 195.07437518290286
    },
    {
        Start: 'EA1',
        End: 'SPORT-G',
        distance: 2.6832653699901696,
        originalDistance: '2.68145731841406',
        max: '2',
        course: 304.6341995450498,
        reverseCourse: 124.58610373377934
    },
    {
        Start: 'EA2',
        End: 'EA1',
        distance: 4.133225333369222,
        originalDistance: '4.13044026236509',
        max: '2',
        course: 3.6605903143727687,
        reverseCourse: 183.66633211701156
    },
    {
        Start: 'EA2',
        End: 'KG10',
        distance: 10.177949181838184,
        originalDistance: '10.1710910241377',
        max: '2',
        course: 10.44073217644086,
        reverseCourse: 190.4809802753556
    },
    {
        Start: 'EA2',
        End: 'MN1-GZ2',
        distance: 4.266587987079758,
        originalDistance: '4.26371305297099',
        max: '2',
        course: 258.7654029194009,
        reverseCourse: 78.67456917512936
    },
    {
        Start: 'EA2',
        End: 'SPORT-G',
        distance: 5.9746881692468925,
        originalDistance: '5.97066227434962',
        max: '2',
        course: 341.0113637149762,
        reverseCourse: 160.96903272733402
    },
    {
        Start: 'EA2',
        End: 'SPORT-H',
        distance: 2.4257865056925394,
        originalDistance: '2.42415194983982',
        max: '2',
        course: 306.61232891788654,
        reverseCourse: 126.57001775188263
    },
    {
        Start: 'EA2',
        End: 'SPORT-I',
        distance: 5.265680717103825,
        originalDistance: '5.26213256922898',
        max: '2',
        course: 75.13513699231333,
        reverseCourse: 255.245724345232
    },
    {
        Start: 'EA2',
        End: 'SPORT-J',
        distance: 2.725873914399327,
        originalDistance: '2.72403715211582',
        max: '2',
        course: 167.06272981001678,
        reverseCourse: 347.0759643954135
    },
    {
        Start: 'KG18',
        End: 'KG10',
        distance: 2.1819439137758834,
        originalDistance: '2.18047366518392',
        max: '2',
        course: 35.943089041324924,
        reverseCourse: 215.9710626429268
    },
    {
        Start: 'MN1-GZ2',
        End: 'SPORT-G',
        distance: 6.857257371347392,
        originalDistance: '6.8526367791624',
        max: '2',
        course: 18.98229597368976,
        reverseCourse: 199.03085987605974
    },
    {
        Start: 'MN1-GZ2',
        End: 'SPORT-H',
        distance: 3.1931779453986135,
        originalDistance: '3.19102630192635',
        max: '2',
        course: 44.397830892964464,
        reverseCourse: 224.44637204580013
    },
    {
        Start: 'MN1-GZ2',
        End: 'SPORT-I',
        distance: 9.527538244720722,
        originalDistance: '9.52111835023944',
        max: '2',
        course: 76.66912439282657,
        reverseCourse: 256.8705489336794
    },
    {
        Start: 'MN1-GZ2',
        End: 'SPORT-J',
        distance: 5.130817686898231,
        originalDistance: '5.12736041311922',
        max: '2',
        course: 110.75034163023633,
        reverseCourse: 290.85438181795735
    },
    {
        Start: 'SPORT-G',
        End: 'KG18',
        distance: 3.607582492264262,
        originalDistance: '3.60515161260386',
        max: '2',
        course: 44.00306994681284,
        reverseCourse: 224.057745073265
    },
    {
        Start: 'SPORT-G',
        End: 'SPORT-J',
        distance: 8.6901254052166,
        originalDistance: '8.6842697805318',
        max: '2',
        course: 162.86401447959224,
        reverseCourse: 342.9195758963607
    },
    {
        Start: 'SPORT-H',
        End: 'EA1',
        distance: 3.4728527960246485,
        originalDistance: '3.47051270061637',
        max: '2',
        course: 39.50121699098963,
        reverseCourse: 219.54929032675392
    },
    {
        Start: 'SPORT-H',
        End: 'KG18',
        distance: 7.2450443075337985,
        originalDistance: '7.24016241477505',
        max: '2',
        course: 20.237730808360084,
        reverseCourse: 200.29238043639316
    },
    {
        Start: 'SPORT-H',
        End: 'SPORT-G',
        distance: 4.2028319737061635,
        originalDistance: '4.20000000000012',
        max: '2',
        course: 0,
        reverseCourse: 180
    },
    {
        Start: 'SPORT-H',
        End: 'SPORT-J',
        distance: 4.835127540894339,
        originalDistance: '4.8318695105595',
        max: '2',
        course: 148.02466798798685,
        reverseCourse: 328.08020335401994
    },
    {
        Start: 'SPORT-I',
        End: 'KG18',
        distance: 8.244764165359763,
        originalDistance: '8.23920863626041',
        max: '2',
        course: 326.820258965474,
        reverseCourse: 146.72186953623498
    },
    {
        Start: 'SPORT-I',
        End: 'KG10',
        distance: 9.246676360561247,
        originalDistance: '9.24044571786954',
        max: '2',
        course: 339.5658663744392,
        reverseCourse: 159.49540983004533
    },
    {
        Start: 'KG10',
        End: 'KG',
        distance: 3.4542304532128107,
        originalDistance: '3.4517585729733',
        max: '2',
        course: 44.11906316643126,
        reverseCourse: 224.17165892569156,
        fixed: 70,
    },
    {
        Start: 'SPORT-I',
        End: 'SPORT-H',
        distance: 7.037262726161595,
        originalDistance: '7.0325208418492',
        max: '2',
        course: 270.89121385789963,
        reverseCourse: 90.73829114688544
    },
    {
        Start: 'SPORT-I',
        End: 'SPORT-J',
        distance: 6.010273253498215,
        originalDistance: '6.00622338047733',
        max: '2',
        course: 228.29144758194371,
        reverseCourse: 48.19412954195411
    },
    {
        Start: 'SPORT-J',
        End: 'KG10',
        distance: 12.726095414272008,
        originalDistance: '12.7175202516624',
        max: '2',
        course: 5.578425980355462,
        reverseCourse: 185.6054128441465
    },
    {
        Start: 'EA1',
        End: 'SPORT-I',
        distance: 5.5660402363818475,
        originalDistance: '5.56228969872168',
        max: '2',
        course: 119.8977696810826,
        reverseCourse: 300.0026651882134
    },
    {
        Start: 'SPORT-M',
        End: 'KG10',
        distance: 4.751580255196176,
        originalDistance: '4.74837852112042',
        max: '2',
        course: 336.59489360681437,
        reverseCourse: 156.55368050514653
    },
    {
        Start: 'SPORT-M',
        End: 'KG18',
        distance: 4.097395310827148,
        originalDistance: '4.09463438299164',
        max: '2',
        course: 309.30811934874123,
        reverseCourse: 129.23895425234628
    },
    {
        Start: 'SPORT-M',
        End: 'EA1',
        distance: 3.794859437388878,
        originalDistance: '3.79230236582088',
        max: '2',
        course: 246.3587636624111,
        reverseCourse: 66.28307589206088
    },
    {
        Start: 'SPORT-M',
        End: 'SPORT-I',
        distance: 4.507957796863244,
        originalDistance: '4.50492022171664',
        max: '2',
        course: 162.63747725863755,
        reverseCourse: 342.6667261598511
    }
];