import './App.css';
import {MapContainer, Marker, Polyline, Popup, TileLayer, Tooltip} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

import {markers, routes} from "./2023_markers.js";
import {estimateBoatSpeed, estimateDirection, estimateTWS} from "./speedInterpolation.js";
import L from 'leaflet';

import iconMarker from 'leaflet/dist/images/marker-icon.png'
import iconRetina from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import {useEffect, useState} from "react";

const icon = L.icon({
    iconRetinaUrl:iconRetina,
    iconUrl: iconMarker,
    shadowUrl: iconShadow,
    iconAnchor:[12, 41],
});

let speedCache = [];

function App() {

    const [shouldUseTileLayer,setShouldUseTileLayer] = useState(false);
    const [currentPath, setCurrentPath] = useState([]);
    const [iteration, setIteration] = useState(0);
    const [showMarkers, setShowMarkers] = useState(true);
    const [realLifeCoeff, setRealLifeCoeff] = useState(0.845);
    const [showExtendedRoute, setShowExtendedRoute] = useState(true);
    const [startingPoint, setStartingPoint] = useState("");
    const [showAutoRouter, setShowAutoRouter] = useState(false);
    const [possibleNext, setPossibleNext] = useState("");
    const [savedPath, setSavedPath] = useState([]);

    useEffect(() => {
        const savedPathFromStorage = localStorage.getItem('savedPath');
        if (savedPathFromStorage) {
            setSavedPath(JSON.parse(savedPathFromStorage));
        }
        if (savedPath.length === 0) {
            addPointToPath("LELY-Z");
            addPointToPath("SPORT-I");
            addPointToPath("EA2");
            addPointToPath("MN1-GZ2");
            addPointToPath("SPORT-I");
            addPointToPath("KG10");
            addPointToPath("KG");
        }
    }, []);


    let routesDone = [];

    function updateRoutingProgress(iteration, totalIterations) {
        const progressPercentage = (iteration / totalIterations) * 100;
        console.log(`Routing progress: ${progressPercentage.toFixed(2)}%`);
        // You can update a progress bar or any other UI element here to show the progress to the user
    }

    function drawPath(path){
        return path.map((pathItem, index) => {
            const {marker, nextMarker} = pathItem;
            const time = getStructuredPathTime(path.slice(0,index+1)).toFixed(2);
            return (
                <Polyline positions={[[marker.Latitude,marker.Longitude],[nextMarker.Latitude,nextMarker.Longitude]]}>
                    <Tooltip sticky>
                        {marker.Name} &rarr; {nextMarker.Name}<br />
                        Distance: {getDistanceBetweenLocations(marker.Latitude, marker.Longitude, nextMarker.Latitude, nextMarker.Longitude).toFixed(2)} NM<br />
                        Course: {getCourseBetweenMarkers(marker, nextMarker).toFixed(2)}&deg;<br />
                        CourseToWind: {(Math.abs(estimateDirection(time) - getCourseBetweenMarkers(marker, nextMarker))> 180 ? 360 - Math.abs(estimateDirection(time) - getCourseBetweenMarkers(marker, nextMarker)) : Math.abs(estimateDirection(time) - getCourseBetweenMarkers(marker, nextMarker))).toFixed(2)}&deg;<br />
                    </Tooltip>
                </Polyline>
            )
        })
    }

    function runRouting() {
        const totalIterations = 10001;
        let previousDistance = 0;

        function runIteration(index) {
            if (index >= totalIterations) {
                // Routing process is complete
                return;
            }

            const testPath = route();

            const distance = getStructuredPathDistance(testPath);

            if (distance > previousDistance) {
                setCurrentPath(testPath);
                previousDistance = distance;
            }


            if (index % 10 === 0) {
                updateRoutingProgress(index, totalIterations);
                setIteration(index);
            }
            // Schedule the next iteration with a delay (e.g., 0 milliseconds) to update the UI
            setTimeout(() => runIteration(index + 1), 0);
        }

        // Start the routing process with the first iteration
        runIteration(0);
    }


    function route() {
        const startNode = startingPoint;
        let path = [];
        path.push(startNode);
        //--
        /**
        path.push("SPORT-B");
        path.push("SPORT-A");
        path.push("VF-B");
        path.push("SPORT-A");
        path.push("LC1");
        path.push("SPORT-B");
            */

        routesDone = [];

        while (getPathTimeWithFinish(path) < 22) {
            let testingRoutes = getAllowedRoutes(path[path.length - 1]);

            if (testingRoutes.length === 0) {
                console.log("No allowed routes");
                console.log("Routes done: ", routesDone);
                break;
            }
            let allowedRoutes = testingRoutes;

            let randomRoute = allowedRoutes[Math.floor(Math.random() * allowedRoutes.length)];
            let doneRoutes = routesDone.filter(route => (route.from === randomRoute.Start && route.to === randomRoute.End) || (route.from === randomRoute.End && route.to === randomRoute.Start))

            if (doneRoutes.length > 0) {
                console.log("Done routes with streak "+randomRoute.Start + "->" + randomRoute.End + ": ",doneRoutes);
                if (doneRoutes[0].count >= findInRouteList(doneRoutes[0].from,doneRoutes[0].to).max) {
                    console.log("excluding this direction");
                    continue
                }
            }


            if (randomRoute.Start === path[path.length - 1]) {
                path.push(randomRoute.End);
            } else {
                path.push(randomRoute.Start);
            }

            incrementCount(routesDone, randomRoute.Start, randomRoute.End);

        }

        path = appendToFinish(path);

        const routeStructure = [];

        for(let i = 0; i < path.length; i++){
            const marker = findInMarkerList(path[i]);
            const nextMarker = findInMarkerList(path[i + 1]);
            if(nextMarker){
                let routePart = findInRouteList(path[i], path[i+1]);

                let time = getStructuredPathTime(routeStructure);

                routeStructure.push({
                    distance: routePart.distance,
                    course: routePart.Start === path[i]? routePart.course:routePart.reverseCourse,
                    speed: getSpeedBetweenMarkers(marker, nextMarker, time),
                    time: getTimeBetweenMarkers(marker,nextMarker, time),
                    marker,
                    nextMarker
                });
            }
        }
        if (getStructuredPathTime(routeStructure) > 24) {
            console.log("Path is too long!");
            console.log("Path: ", routeStructure);
            //console.log("Time: ", getStructuredPathTime(routeStructure));
            return [];
        }
        return routeStructure;
    }

    function getStructuredPathDistance(path){
        return path.reduce((acc, pathItem) => {
            const distance = pathItem.distance;
            return acc + distance;
        }, 0);
    }

    function getStructuredPathTime(path){

        let time = 0;
        path.forEach(pathItem => {
            const {marker, nextMarker} = pathItem;
            time += getTimeBetweenMarkers(marker, nextMarker, time);
        })
        return time;
    }

    function getPathTimeWithFinish(path) {
        path = appendToFinish(path);
        let time = 0;
        for (let i = 0; i < path.length - 1; i++) {
            let route = findInRouteList(path[i], path[i + 1]);
            let startMarker = findInMarkerList(path[i]);
            let endMarker = findInMarkerList(path[i+1]);
            time += (route.distance / getSpeedBetweenMarkers(startMarker,endMarker, time));
        }
        return time;
    }

    function getTimeBetweenMarkers(marker1, marker2, previousTime) {
        let route = findInRouteList(marker1.Name, marker2.Name);
        if (route.fixed) {
            return route.fixed/60;
        }
        return (route.distance / (realLifeCoeff * getSpeedBetweenMarkers(marker1,marker2, previousTime)));
    }

    function printExtendedRoute(path) {
        return path.map((pathItem,index) => {
            const {marker, nextMarker} = pathItem;
            const time = getStructuredPathTime(path.slice(0,index+1)).toFixed(2);
            const timePrev = getStructuredPathTime(path.slice(0,index)).toFixed(2);
            return (

                    <div style={{borderBottom:"1px dashed #CCC", marginBottom:"10px", paddingBottom:"10px"}}>
                        <b>{marker.Name} &rarr; {nextMarker.Name}</b><br />
                        Distance: {getDistanceBetweenLocations(marker.Latitude, marker.Longitude, nextMarker.Latitude, nextMarker.Longitude).toFixed(2)} NM<br />
                        Course: {getCourseBetweenMarkers(marker, nextMarker).toFixed(2)}&deg;<br />
                        WindDirection: {estimateDirection(timePrev).toFixed(2)}&deg;<br />
                        WindSpeed: {estimateTWS(timePrev).toFixed(2)} kts<br />
                        CourseToWind: {(Math.abs(estimateDirection(timePrev) - getCourseBetweenLocations(marker.Latitude, marker.Longitude, nextMarker.Latitude, nextMarker.Longitude))> 180 ? 360 - Math.abs(estimateDirection(timePrev) - getCourseBetweenLocations(marker.Latitude, marker.Longitude, nextMarker.Latitude, nextMarker.Longitude)) : Math.abs(estimateDirection(timePrev) - getCourseBetweenLocations(marker.Latitude, marker.Longitude, nextMarker.Latitude, nextMarker.Longitude))).toFixed(2)}&deg;<br />
                        Speed: {(realLifeCoeff * getSpeedBetweenMarkers(marker, nextMarker, timePrev)).toFixed(2)} kts<br />
                        Leg time: {(60 * getTimeBetweenMarkers(marker, nextMarker, time)).toFixed(2)} min<br />
                        Time Elapsed: {(60 * time).toFixed(2)} min<br />
                        ETA: {new Date(new Date().setHours(18,30,0) + (60 * time * 60000)).toLocaleTimeString()}<br />
                    </div>
            )
        }, '')
    }

    function addPointToPath(point) {
        console.log("Adding point to path");
        console.log("point: "+point)
        var tmp = savedPath
        tmp.push(point)
        setSavedPath(tmp)
        setPossibleNext("");
        setCurrentPath(getStructuredPath(savedPath));
        const r = getAllowedRoutes(savedPath[savedPath.length - 1]);
        console.log(r)
    }
    function getStructuredPath(path) {
        const routeStructure = [];

        for(let i = 0; i < path.length; i++){
            const marker = findInMarkerList(path[i]);
            const nextMarker = findInMarkerList(path[i + 1]);
            if(nextMarker){
                let routePart = findInRouteList(path[i], path[i+1]);

                let time = getStructuredPathTime(routeStructure);

                routeStructure.push({
                    distance: routePart.distance,
                    course: routePart.Start === path[i]? routePart.course:routePart.reverseCourse,
                    speed: getSpeedBetweenMarkers(marker, nextMarker, time),
                    time: getTimeBetweenMarkers(marker,nextMarker, time),
                    marker,
                    nextMarker
                });
            }
        }
        return routeStructure;
    }


    return (
      <div className="top-container" style={{height: '100vh'}}>
          <div className="app">
              <MapContainer
                  center={[52.80, 5.18]}
                  zoom={9}
                  style={{ height: '100%' }}
              >
                  <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  { shouldUseTileLayer ?
                  <TileLayer
                      url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"
                  /> : null
                  }
                  {showMarkers && markers.map((marker, index) => (
                      <Marker
                          key={index}
                          position={[marker.Latitude, marker.Longitude]}
                          icon={icon}
                      >
                          <Tooltip direction="bottom" offset={[0, 0]} opacity={0.5} permanent>
                              <div>
                                 {marker.Name}
                              </div>
                          </Tooltip>
                      </Marker>
                  ))}
                  {drawPath(currentPath)}
              </MapContainer>
          </div>
          <div className="sidebar">
              <h1>24hrs</h1>
              <hr/>
              <input type="checkbox" id="tile-layer" onChange={(e) => {
                  setShouldUseTileLayer(e.target.checked);
              }}/> <label htmlFor="tile-layer">Show marine layer</label><br/>
              <input type="checkbox" checked={showMarkers} id="marine-layer" onChange={(e) => {
                  setShowMarkers(e.target.checked);
              }}/> <label htmlFor="marine-layer">Show markers</label><br/>
              <input type="checkbox" checked={true} id="marker-layer" disabled={true}/> <label htmlFor="tile-layer">Avoid
              Locks</label><br/>
              <input type="checkbox" checked={showExtendedRoute} id="marker-layer2" onChange={(e) => {
                  setShowExtendedRoute(e.target.checked);
              }}/> <label htmlFor="tile-layer">Show extended Route</label><br/>
              <hr/>
              <h2>Adjustments</h2>
              <p>Real-life polar adjustment coefficient: <br/>
                  <input value={realLifeCoeff} onChange={(e) => {
                      setRealLifeCoeff(e.target.value);
                  }}/></p>
              <hr/>
              {savedPath.length === 0 ? <>
                  Starting point:<br/>
                  <select value={startingPoint} onChange={(e)=>setStartingPoint(e.target.value)}>
                      {markers.filter((m) => {
                          return m.Type === "start"
                      }).map((marker, index) => (
                          <option key={marker.Name}>{marker.Name}</option>
                      ))}
                  </select><br />
                  <button className={"downsized"} disabled={false} onClick={()=>{addPointToPath(startingPoint)}}>Start here</button>
              </> : <>

                  Next options:
              {getAllowedRoutes(savedPath[savedPath.length - 1]).map((marker) => {
                  const estimatedSpeed = (realLifeCoeff * estimateBoatSpeed(marker.Start === savedPath[savedPath.length - 1] ? marker.course : marker.reverseCourse, getStructuredPathTime(getStructuredPath(savedPath)))).toFixed(2);
                  return marker.Start === savedPath[savedPath.length - 1] ?
                      <div className={"possibleNext"} key={marker.End}>
                          <div>
                              <b>{marker.End}</b><br/>
                              Course: {marker.course.toFixed(2)}<br/>
                              Distance: {marker.distance.toFixed(2)}<br/>
                              Speed: {estimatedSpeed} kts<br/>
                          </div>

                          <button className={"downsized"} disabled={false}
                                  onClick={() => addPointToPath(marker.End)}>Add Next
                          </button>

                      </div> :
                      <div className={"possibleNext"} key={marker.Start}>
                          <div>
                              <b>{marker.Start}</b><br/>Course: {marker.reverseCourse.toFixed(2)}<br/>Distance: {marker.distance.toFixed(2)}<br/>
                              Speed: {estimatedSpeed} kts<br/>
                          </div>

                              <button className={"downsized"} disabled={false}
                                  onClick={() => addPointToPath(marker.Start)}>Add Next
                          </button>
                      </div>
              })}

              </>}
              <hr/>
              {currentPath.length > 0 ?
                  !showExtendedRoute ?
                      <div>

                          <h2>Route</h2>
                          <p><b>{printRoute(currentPath)}</b></p>
                          <p>Distance: {getStructuredPathDistance(currentPath).toFixed(2)} NM</p>
                          <p>Time: {getStructuredPathTime(currentPath).toFixed(2)} hours</p>
                          <hr/>
                      </div>
                      :
                      <div>
                          <h2>Extended Route</h2>
                          {printExtendedRoute(currentPath)}
                          <p>Distance: {getStructuredPathDistance(currentPath).toFixed(2)} NM</p>
                          <p>Time: {getStructuredPathTime(currentPath).toFixed(2)} hours</p>
                          <hr/>
                      </div>
                  : null
              }
              {showAutoRouter ? <>
                  <div>
                      <p>Iteration: {iteration}</p>
                      <p>Progress: {((iteration / 10000) * 100).toFixed(2)}%</p>
                      <hr/>
                  </div>
                  <button className="routebtn" disabled={iteration > 0 && iteration < 10000}
                          onClick={runRouting}>Route
                  </button>
              </> : null}
          </div>
      </div>
    );
}

// function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2)
function getDistanceBetweenLocations(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c * 0.5399568; // Distance in nautical miles
}

function getCourseBetweenLocations(lat1, lon1, lat2, lon2) {
    const dLon = deg2rad(lon2-lon1);
    const y = Math.sin(dLon) * Math.cos(deg2rad(lat2));
    const x = Math.cos(deg2rad(lat1))*Math.sin(deg2rad(lat2)) -
        Math.sin(deg2rad(lat1))*Math.cos(deg2rad(lat2))*Math.cos(dLon);
    const brng = rad2deg(Math.atan2(y, x));
    return (brng + 360) % 360;
}

function getCourseBetweenMarkers(marker1,marker2) {
    let routePart = findInRouteList(marker1.Name, marker2.Name);
    return routePart.Start === marker1.Name? routePart.course:routePart.reverseCourse;
}

function deg2rad(deg) {
    return deg * (Math.PI/180)
}

function rad2deg(rad) {
    return rad * (180/Math.PI)
}

function printRoute(path){
    return path.map((pathItem, index) => {
        const {marker, nextMarker} = pathItem;
        return (
            (index === path.length - 1) ?
                <span>{marker.Name}  &rarr; {nextMarker.Name}</span>
            :
                <span>{marker.Name} &rarr; </span>

        )
    }, '')
}


function getSpeedBetweenMarkers(marker1,marker2, time) {

    // boatSpeed is in knots
    const course = getCourseBetweenMarkers(marker1,marker2);
    //speedCache.push({tws,twa,estimatedSpeed});
    return estimateBoatSpeed(course, time);
}

function incrementCount(done, from, to) {
    for (const entry of done) {
        if ((entry.from === from && entry.to === to) || (entry.from === to && entry.to === from) ) {
            entry.count++;
            return; // Exit the function after incrementing
        }
    }
    // If no matching entry is found, add a new entry
    done.push({ from: from, to: to, count: 1 });
}
function findInRouteList(from, to) {
    return routes.filter(route => (route.Start === from && route.End === to) || (route.End === from && route.Start === to) )[0];
}
function findInMarkerList(marker) {
    return markers.filter(m => m.Name === marker).length > 0 ? markers.filter(m => m.Name === marker)[0] : null
}

function getAllowedRoutes(from) {
    let rp = routes.filter(route => route.Start === from).concat(routes.filter(route => route.End === from));
    // don't allow to go back to start

    return rp.filter(route => {if (from === route.Start) { let rt = findInMarkerList(route.End); return rt.Type !=="start" && rt.Type !== "finish"} else {let rt = findInMarkerList(route.Start); return rt.Type !=="start" && rt.Type !== "finish"}});
}


function appendToFinish(path)
{
    const graph = {};
    for (const route of routes) {
        const start = route.Start;
        const end = route.End;

        if (!graph[start]) {
            graph[start] = [];
        }
        if (!graph[end]) {
            graph[end] = [];
        }

        graph[start].push(end);
        graph[end].push(start); // Add the reverse edge
    }

// Breadth-First Search function
    function bfs(start, target) {
        const queue = [{ node: start, path: [start] }];
        const visited = new Set();

        while (queue.length > 0) {
            const { node, path } = queue.shift();

            if (node === target) {
                return path; // Found a route
            }

            if (!visited.has(node)) {
                visited.add(node);
                const neighbors = graph[node] || [];
                for (const neighbor of neighbors) {
                    if (!visited.has(neighbor)) {
                        queue.push({ node: neighbor, path: [...path, neighbor] });
                    }
                }
            }
        }

        return null; // No route found
    }

    const startNode = path[path.length - 1];
    const endNode = "FINISH";

    const route = bfs(startNode, endNode);
    return path.concat(route.slice(1));
}

export default App;
